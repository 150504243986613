<template>
  <el-form ref="form" :model="fillForm" :rules="rules">
    <div class="item" v-if="flag1.includes(type)">
      <div class="left">
        <div class="fill-title">
          {{
            type == 0
              ? '店铺有支付'
              : type == 1
              ? '店铺无支付'
              : type == 2
              ? '店铺有加购物车'
              : type == 3
              ? '店铺无加购物车'
              : type == 4
              ? '店铺有访问'
              : type == 5
              ? '店铺无访问'
              : type == 8
              ? '店铺有下单'
              : type == 9
              ? '店铺无下单'
              : '店铺会员'
          }}
        </div>
        <div class="fill-tips">
          {{
            type == 0
              ? '选定时间内，购买过本店商品的客户(以支付成功为准，不剔除退款)'
              : type == 1
              ? '选定时间内，没有购买过本店商品的客户(以支付成功为准，不剔除退款)'
              : type == 2
              ? '选定时间内，加购过本店商品的客户'
              : type == 3
              ? '选定时间内，没有加购过本店商品的客户'
              : type == 4
              ? '选定时间内，访问过本店的客户'
              : type == 5
              ? '选定时间内，没有访问过本店的客户'
              : type == 8
              ? '选定时间内，在店铺有下单行为的客户'
              : type == 9
              ? '选定时间内，在店铺无下单行为的客户'
              : '选定时间内，成为店铺会员'
          }}
        </div>
        <el-form-item class="fill-input" prop="day">
          最近
          <el-input class="input1" v-model.number="fillForm.day" placeholder="请输入1~720的数值"></el-input>
          天
        </el-form-item>
      </div>
      <div class="right" @click="$emit('deleteGroup', type)">
        <el-image :src="require('@/assets/image/delete.png')"></el-image>
        删除
      </div>
    </div>
    <div class="item" v-if="flag2.includes(type)">
      <div class="left">
        <div class="fill-title">{{ type == 6 ? '有效购买次数' : type == 7 ? '有效购买金额' : type == 10 ? '无退款订单数' : type == 11 ? '每笔单价' : '储蓄金额' }}</div>
        <div class="fill-tips">
          {{
            type == 6
              ? '客户累计在店铺内支付成功的订单数量，删除全部退款的订单'
              : type == 7
              ? '客户累计在店铺内支付成功的订单金额，删除退款金额'
              : type == 10
              ? '客户累计在店内支付成功的订单数量，剔除全部退款的订单'
              : type == 11
              ? '客户在店铺的消费金额/购次'
              : '客户当前账户内储值余额（包含赠送金额）'
          }}
        </div>
        <div class="fill-input">
          <el-select class="select1" v-model="fillForm.scope_val">
            <el-option v-for="item in scope_options" :label="item.label" :value="item.val" :key="item.val"></el-option>
          </el-select>
          <el-form-item v-if="fillForm.scope_val != 'between'" prop="num">
            <el-input-number v-model="fillForm.num" controls-position="right"></el-input-number>
            {{ type == 6 ? '次' : type == 7 || type == 11 || type == 15 ? '元' : '个' }}
          </el-form-item>
          <template v-else>
            <el-form-item prop="start_num">
              <el-input-number v-model="fillForm.start_num" controls-position="right"></el-input-number>
              <span style="margin-right: 7px">
                {{ type == 6 ? '次' : type == 7 || type == 11 || type == 15 ? '元' : '个' }}
                <i style="color: #d6cece">—</i>
              </span>
            </el-form-item>
            <el-form-item prop="end_num">
              <el-input-number v-model="fillForm.end_num" controls-position="right"></el-input-number>
              {{ type == 6 ? '次' : type == 7 || type == 11 || type == 15 ? '元' : '个' }}
            </el-form-item>
          </template>
        </div>
      </div>
      <div class="right" @click="$emit('deleteGroup', type)">
        <el-image :src="require('@/assets/image/delete.png')"></el-image>
        删除
      </div>
    </div>
    <div class="item" v-if="type == 13">
      <div class="left">
        <div class="fill-title">会员等级</div>
        <div class="fill-tips">用户当前的会员等级</div>
        <el-form-item prop="cardLevel_id" style="margin-bottom: 10px">
          <el-checkbox-group v-model="fillForm.cardLevel_id">
            <el-checkbox v-for="(item, index) in cardLevel" :key="index" :label="item.id">{{ item.title }}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </div>
      <div class="right" @click="$emit('deleteGroup', type)">
        <el-image :src="require('@/assets/image/delete.png')"></el-image>
        删除
      </div>
    </div>
    <div class="item" v-if="type == 14">
      <div class="left">
        <div class="fill-title">来源渠道</div>
        <div class="fill-tips">从以下任一渠道（如微信、支付宝、快应用）方式进入成为客户</div>
        <el-form-item>
          <el-select class="select1" v-model="fillForm.comfrom_val">
            <el-option v-for="item in comfrom_options" :label="item.label" :value="item.val" :key="item.val"></el-option>
          </el-select>
        </el-form-item>
      </div>
      <div class="right" @click="$emit('deleteGroup', type)">
        <el-image :src="require('@/assets/image/delete.png')"></el-image>
        删除
      </div>
    </div>
  </el-form>
</template>

<script>
export default {
  props: ['type', 'val_key', 'groupInfo', 'cardLevel'],
  data() {
    const validateDay = (rule, value, callback) => {
      if (value > 720) {
        callback(new Error('天数不能大于720'));
      } else {
        callback();
      }
    };
    const validateNum = (rule, value, callback) => {
      if (value < 0) {
        callback(new Error('请填写有效值'));
      } else {
        callback();
      }
    };
    return {
      flag1: [0, 1, 2, 3, 4, 5, 8, 9, 12],
      flag2: [6, 7, 10, 11, 15],
      scope_options: [
        {
          val: '>',
          label: '大于',
        },
        {
          val: '<',
          label: '小于',
        },
        {
          val: 'between',
          label: '区间',
        },
      ],
      comfrom_options: [
        {
          val: '',
          label: '全部渠道',
        },
        {
          val: 1,
          label: '微信',
        },
        {
          val: 2,
          label: '支付宝',
        },
        {
          val: 3,
          label: '快应用',
        },
        {
          val: 4,
          label: '抖音',
        },
        {
          val: 5,
          label: 'QQ',
        },
        {
          val: 6,
          label: '百度',
        },
      ],
      fillForm: {
        scope_val: '>',
        comfrom_val: '',
        cardLevel_id: [],
        num: undefined,
        day: '',
        start_num: undefined,
        end_num: undefined,
      },
      rules: {
        day: [{ required: !0, message: '请填写有效天数', trigger:'blur' }, { type: 'number', message: '请填写有效天数' }, { validator: validateDay }],
        num: [{ required: !0, message: '请填写有效值', trigger: 'blur' }, { type: 'number', message: '请填写有效值' }, { validator: validateNum }],
        start_num: [{ required: !0, message: '请填写有效值', trigger: 'blur' }, { type: 'number', message: '请填写有效值' }, { validator: validateNum }],
        end_num: [{ required: !0, message: '请填写有效值', trigger: 'blur' }, { type: 'number', message: '请填写有效值' }, { validator: validateNum }],
        cardLevel_id: { type: 'array', required: !0, message: '请至少选择一个会员等级', trigger: ['blur', 'change'] },
      },
    };
  },
  watch: {
    val_key: {
      handler(val) {
        if (val) {
          if (this.flag1.includes(this.type)) {
            this.fillForm.day = Number(this.groupInfo[val]);
          }
          if (this.flag2.includes(this.type)) {
            if (this.groupInfo[val].mark == 'between') {
              this.fillForm.start_num = Number(this.groupInfo[val].num[0]);
              this.fillForm.end_num = Number(this.groupInfo[val].num[1]);
            } else {
              this.fillForm.num = Number(this.groupInfo[val].num);
            }
            this.fillForm.scope_val = this.groupInfo[val].mark;
          }
          if (this.type == 13) this.fillForm.cardLevel_id = this.groupInfo[val].level.map(Number);
          if (this.type == 14) this.fillForm.comfrom_val = Number(this.groupInfo[val]);
        }
      },
      immediate: !0,
    },
  },
  methods: {
    validate() {
      let type = this.type;
      let data = {};
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.flag1.includes(type)) {
            switch (type) {
              case 0:
                data.key = 'store_pay';
                break;
              case 1:
                data.key = 'store_nopay';
                break;
              case 2:
                data.key = 'add_cart';
                break;
              case 3:
                data.key = 'add_nocart';
                break;
              case 4:
                data.key = 'store_visit';
                break;
              case 5:
                data.key = 'store_novisit';
                break;
              case 8:
                data.key = 'order_submit';
                break;
              case 9:
                data.key = 'noorder_submit';
                break;
              case 12:
                data.key = 'vip';
                break;
            }
            data.value = this.fillForm.day;
          }
          if (this.flag2.includes(type)) {
            switch (type) {
              case 6:
                data.key = 'buy_times';
                break;
              case 7:
                data.key = 'buy_amount';
                break;
              case 10:
                data.key = 'norefund';
                break;
              case 11:
                data.key = 'costprice';
                break;
              case 15:
                data.key = 'balance';
                break;
            }
            if (this.fillForm.scope_val == 'between') {
              data.value = {
                num: [this.fillForm.start_num, this.fillForm.end_num],
                mark: this.fillForm.scope_val,
              };
            } else {
              data.value = {
                num: this.fillForm.num,
                mark: this.fillForm.scope_val,
              };
            }
          }
          if (type == 13) {
            data.key = 'member_level';
            data.value = {
              level: this.fillForm.cardLevel_id,
            };
          }
          if (type == 14) {
            data.key = 'comfrom';
            data.value = this.fillForm.comfrom_val;
          }
          data.rules = !0;
        } else {
          data.rules = !1;
        }
      });
      return data;
    },
  },
};
</script>

<style lang="less" scoped>
.item {
  background: #fff;
  padding: 15px 20px;
  display: flex;
  margin-bottom: 15px;
  justify-content: space-between;
  .left {
    .fill-title {
      margin-bottom: 10px;
    }
    .fill-tips {
      margin-bottom: 10px;
      color: #999;
    }
    .fill-input {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      .el-form-item {
        margin: 0;
      }
      .input1 {
        width: 180px;
        margin: 0 7px;
      }
      .select1 {
        width: 180px;
        margin-right: 7px;
      }
      .el-input-number {
        width: 120px;
        margin-right: 7px;
      }
    }
  }
  .right {
    display: flex;
    align-items: center;
    color: #999;
    cursor: pointer;
    .el-image {
      width: 17px;
      height: 18px;
      margin-right: 3px;
    }
  }
}
</style>
