import { render, staticRenderFns } from "./groupEmpty.vue?vue&type=template&id=0172126f&scoped=true&"
var script = {}
import style0 from "./groupEmpty.vue?vue&type=style&index=0&id=0172126f&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0172126f",
  null
  
)

export default component.exports